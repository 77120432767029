

























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User, {UserRole} from '@/models/User.model';
import {userStoreActions, userStoreGetter, userStoreMutations} from '@/store/user.store';
import ShipOwner from '@/models/ShipOwner.model';
import {shipOwnerStoreActions, shipOwnerStoreGetter} from '@/store/shipOwner.store';

const UserStore = namespace('user');
const ShipOwnerStore = namespace('shipOwner');

@Component({
  components: {
    EditUserComponent: () => import(/* webpackChunkName: "EditUserComponent" */
        '@/components/settings/EditUser.component.vue')
  }
})
export default class UserTableComponent extends Vue {

  @ShipOwnerStore.Action(shipOwnerStoreActions.GET_ALL)
  public getAllShipOwners!: () => Promise<ShipOwner[]>;

  @ShipOwnerStore.Getter(shipOwnerStoreGetter.LIST_ALL)
  public _shipOwners!: ShipOwner[];

  @UserStore.Action(userStoreActions.UPDATE)
  public updateUser!: (payload: { user: User }) => Promise<User>

  @UserStore.Action(userStoreActions.GET_ALL)
  public getAllUsers!: () => Promise<User[]>;

  @UserStore.Action(userStoreActions.REMOVE_SHIP_OWNER)
  public removeShipOwnerAction!: (payload: { id: string, shipOwner: string }) => Promise<User>

  @UserStore.Action(userStoreActions.ADD_SHIP_OWNER)
  public addShipOwnerAction!: (payload: { id: string, shipOwner: string }) => Promise<User>

  @UserStore.Mutation(userStoreMutations.SET_SELECTED_USER)
  public setSelectedUser!: (user: User | null) => void;

  @UserStore.Getter(userStoreGetter.SELECTED_USER)
  public selectedUser!: User;

  @Prop({default: () => []})
  public users!: User[];

  @Prop({default: ''})
  public searchValue!: string;

  public showCreationDialog: boolean = false;

  get shipOwners(): ShipOwner[] {
    return this._shipOwners.filter(shipOwner => shipOwner.status);
  }

  public UserRole = UserRole;

  public userRoles: UserRole[] = [UserRole.KEY_USER, UserRole.PLANER, UserRole.IT_AUTOMOBILE, UserRole.SHIP_OWNER];

  public tableHeaders = [{
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.FIRSTNAME'),
    value: 'firstname',
    sortable: true
  }, {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.LASTNAME'),
    value: 'lastname',
    sortable: true
  },  {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.USERNAME'),
    value: 'username',
    sortable: true
  }, {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.EMAIL'),
    value: 'email',
    sortable: false
  }, {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.USERROLE'),
    value: 'userRole',
    sortable: false
  }, {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.SHIP_OWNERS'),
    value: 'shipOwners',
    sortable: false
  }, {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.STATUS'),
    value: 'status',
    sortable: false
  },  {
    text: this.$t('SETTINGS.USERS_VIEW.TABLE.LAST_LOGIN'),
    value: 'lastLogin',
    sortable: true,
    sort: (a: Date, b: Date) => {
      return a.getTime() - b.getTime();
    }
  }, {
    text: this.$t('SETTINGS.SHIPS_VIEW.TABLE.OPTIONS'),
    value: 'options',
    sortable: false,
    align: 'right'
  }];

  public async created() {
    await this.getAllShipOwners();
  }

  public async showEditDialog(user: User) {
    this.setSelectedUser(user);
    this.showCreationDialog = true;
  }

  public async closeDialog(reload: boolean) {
    if (reload) {
      await this.getAllUsers();
    }

    this.setSelectedUser(null);
    this.showCreationDialog = false;
  }

  public async updateUserStatus(user: User) {
    user.status = !user.status;
    await this.updateUser({user});
    Vue.notifySuccessSimplified('SETTINGS.USERS_VIEW.NOTIFICATIONS.SAVED_DATA');
  }

  public async saveUser(user: User) {
    await this.updateUser({user});
    Vue.notifySuccessSimplified('SETTINGS.USERS_VIEW.NOTIFICATIONS.SAVED_DATA');
  }

  public async removeShipOwner(user: User, item: ShipOwner) {
    await this.removeShipOwnerAction({id: user.id!, shipOwner: item.id!});
    Vue.notifySuccessSimplified('SETTINGS.USERS_VIEW.NOTIFICATIONS.SAVED_DATA');
  }

  public async addShipOwner(user: User, item: ShipOwner) {
    await this.addShipOwnerAction({id: user.id!, shipOwner: item.id!});
    Vue.notifySuccessSimplified('SETTINGS.USERS_VIEW.NOTIFICATIONS.SAVED_DATA');
  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.KEY_USER);
  }
}

