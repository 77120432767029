var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-table mt-3"},[_c('v-card',{staticClass:"box-standard-shadow py-1"},[_c('v-data-table',{attrs:{"height":"550","items":_vm.users,"headers":_vm.tableHeaders,"search":_vm.searchValue,"items-per-page":10},scopedSlots:_vm._u([{key:"item.userRole",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"role-selection"},[_c('v-select',{attrs:{"items":_vm.userRoles,"disabled":_vm.viewOnly},on:{"change":function($event){return _vm.saveUser(item)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("SETTINGS.USERS_VIEW.USER_ROLES." + item)))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("SETTINGS.USERS_VIEW.USER_ROLES." + item)))+" ")]}}],null,true),model:{value:(item.userRole),callback:function ($$v) {_vm.$set(item, "userRole", $$v)},expression:"item.userRole"}})],1)]}},{key:"item.shipOwners",fn:function(ref){
var item = ref.item;
return [(item.userRole === _vm.UserRole.SHIP_OWNER)?_c('div',{staticClass:"shipOwner-selection"},[_c('v-autocomplete',{attrs:{"items":_vm.shipOwners,"chips":"","color":"blue-grey lighten-2","item-text":"name","return-object":"","multiple":"","disabled":_vm.viewOnly},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"x-small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeShipOwner(item, data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{on:{"click":function($event){return _vm.addShipOwner(item, data.item)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]}}],null,true),model:{value:(item.shipOwners),callback:function ($$v) {_vm.$set(item, "shipOwners", $$v)},expression:"item.shipOwners"}})],1):_vm._e()]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$isoStringDateTimeHumanized(item.lastLogin)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("fas fa-check")])],1):_c('div',{staticClass:"pointer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","small":""}},on),[_vm._v("far fa-times-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('USER.TOOLTIPS.USER_INACTIVE')))])])],1)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.viewOnly},on:{"click":function($event){return _vm.showEditDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.viewOnly},on:{"click":function($event){return _vm.updateUserStatus(item)}}},[(item.status)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ban")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check-circle")])],1)],1)]}}])})],1),(_vm.showCreationDialog)?_c('v-dialog',{attrs:{"max-width":"400","persistent":""},model:{value:(_vm.showCreationDialog),callback:function ($$v) {_vm.showCreationDialog=$$v},expression:"showCreationDialog"}},[_c('edit-user-component',{attrs:{"user":_vm.selectedUser},on:{"closeDialog":_vm.closeDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }